import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { getValue, isObject } from "../audit/Helpers";

/**
 * Utility class for creating and exporting Excel files using exceljs.
 */
class XLSXWorkBook {
  constructor() {
    this.wb = new ExcelJS.Workbook();
  }

  /**
   * Adds a new sheet to the workbook with the given data.
   * @param {string} sheetName - Name of the sheet.
   * @param {Object} data - Table data containing headers and rows.
   */
  addSheet(sheetName, data) {
    // Limit sheet name to 31 characters (Excel limit)
    const ws = this.wb.addWorksheet(sheetName.substring(0, 31));

    // If no data, add a message row
    if (!data.rows || data.rows.length === 0) {
      ws.addRow([`No data for sheet '${sheetName}'`]);
      return;
    }

    // Add headers with bold formatting
    const xlsxHeader = ws.addRow(data.headers);
    xlsxHeader.font = { bold: true };

    // Add rows
    data.rows.forEach((row) => {
      const xlsxRow = ws.addRow(row.map((cell) => this.formatCell(cell)));

      row.forEach((cell, index) => {
        if (!isObject(cell)) return;

        const xlsxCell = xlsxRow.getCell(index + 1);
        if (cell.type === "LINK") {
          xlsxCell.value = {
            text: xlsxCell.text,
            hyperlink: cell.link,
          };
        } else if (cell.type === "TITLE") {
          xlsxCell.font = { bold: true };
        }
      });
    });

    // Auto adjust column widths
    ws.columns.forEach((column, index) => {
      let maxLength = 0;
      column.eachCell((cell) => {
        maxLength = Math.max(maxLength, cell.text ? cell.text.length : 0);
      });
      ws.getColumn(index + 1).width = maxLength + 2;
    });

    // Apply filters and freeze first row
    ws.autoFilter = {
      from: { row: 1, column: 1 },
      to: { row: ws.actualRowCount, column: ws.actualColumnCount },
    };
    ws.views = [{ state: "frozen", ySplit: 1 }];
  }

  /**
   * Ensures the value is treated as a safe string.
   * @param {any} value - Value to sanitize.
   * @returns {string | null}
   */
  formatCell(value) {
    // If the value is an enriched cell (e.g. with a link), extract the display value
    // These will be in the form of an object with a 'value' key
    const displayValue = getValue(value);

    if (displayValue === null || !String(displayValue).trim()) return null;
    const safeStr = String(displayValue);

    // Escape potential formula-injection by prefixing with single quote
    if (/^[=+\-@]/.test(safeStr)) return `'${safeStr}`;

    return safeStr;
  }

  /**
   * Generates the Excel file and triggers a download.
   * @param {string} fileName - The name of the file to save.
   */
  async save(fileName) {
    const buffer = await this.wb.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(blob, fileName);
  }
}

export default XLSXWorkBook;
