import { useEffect, useState } from "react";
import { PaginatedTable, Table } from "../components/Table";
import Icon from "../components/Icon";
import FileInput from "../components/FileInput";
import Select from "react-select";
import AuditConfig from "./AuditConfig";
import _ from "lodash";
import Result from "../components/Result";
import { API_URL } from "../constants";
import { getBenefitValue, getEmployerValue } from "./Helpers";
import XLSXWorkbook from "../xlsx/XLSXWorkbook";
import axios from "axios";

/**
 * Application Stages, used to control the flow of the application.
 */
const Stages = {
  LOADING_EMPLOYERS_AND_BENEFITS: 0,
  SELECT_BENEFITS: 1,
  BENEFITS_SELECTED: 2,
  ACQUIRING_BENEFIT_ENROLLMENTS: 3,
  BENEFIT_ENROLLMENTS_ACQUIRED: 4,

  SELECT_MEMBERSHIP_LIST_FILE: 5,
  MEMBERSHIP_LIST_FILE_SELECTED: 6,
  UPLOADING_MEMBERSHIP_LIST: 7,
  MEMBERSHIP_LIST_UPLOADED: 8,

  LOADING_AUDIT_SETTINGS: 9,
  CONFIGURE_AUDIT_SETTINGS: 10,
  AUDITING: 11,
  AUDIT_COMPLETED: 12,
};

export default function Audit({ auditId, scrollRef, authenticatedAxios }) {
  const [stage, setStage] = useState(Stages.LOADING_EMPLOYERS_AND_BENEFITS);

  const [initError, setInitError] = useState(null);
  const [employers, setEmployers] = useState([]);
  const [selectedEmployers, setSelectedEmployers] = useState([]);
  const [employerSelectionOptions, setEmployerSelectionOptions] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [selectedBenefits, setSelectedBenefits] = useState([]);
  const [benefitSelectionOptions, setBenefitSelectionOptions] = useState([]);
  const [enrollments, setEnrollments] = useState(null);
  const [benefitSelectionError, setBenefitSelectionError] = useState(null);

  const [mlFile, setMLFile] = useState(null);
  const [membershipList, setMembershipList] = useState(null);
  const [uploadMLError, setUploadMLError] = useState(null);

  const [config, setConfig] = useState(null);
  const [configError, setConfigError] = useState(null);

  const [results, setResults] = useState(null);
  const [auditError, setAuditError] = useState(null);

  const [isGeneratingXLSX, setIsGeneratingXLSX] = useState(false);
  const [xlsxError, setXLSXError] = useState(null);

  /**
   * Audit the uploaded file against the selected benefits.
   */
  const handleAudit = async () => {
    setStage(Stages.AUDITING);
    const data = { config: config };

    try {
      const urlResponse = await authenticatedAxios.post(
        `${API_URL}/api/audit/${auditId}/run`,
        data,
      );
      const response = await axios.get(urlResponse.data.url, {
        responseType: "json",
      });

      setResults(response.data);
      setStage(Stages.AUDIT_COMPLETED);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setAuditError(error.response.data.message);
      } else {
        setAuditError("An unexpected error occurred.");
      }
      setStage(Stages.CONFIGURE_AUDIT_SETTINGS);
    }
  };

  /**
   * Clear the audit error when any changes are made to the config
   * or when new results are generated.
   */
  useEffect(() => {
    config && setAuditError(null);
  }, [config, results]);

  /**
   * Define benefits to audit against.
   */
  const handleFetchBenEnrollments = async () => {
    setStage(Stages.ACQUIRING_BENEFIT_ENROLLMENTS);

    try {
      const data = { benefits: selectedBenefits };
      const response = await authenticatedAxios.post(
        `${API_URL}/api/audit/${auditId}/fetch-ben-enrollments`,
        data,
      );

      setEnrollments(response.data);
      setStage(Stages.BENEFIT_ENROLLMENTS_ACQUIRED);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setBenefitSelectionError(error.response.data.message);
      } else {
        setBenefitSelectionError("An unexpected error occurred.");
      }

      setStage(Stages.BENEFITS_SELECTED);
    }
  };

  /**
   * Clear the benefit selection error when new benefits are selected.
   */
  useEffect(() => {
    selectedBenefits && setBenefitSelectionError(null);
  }, [selectedBenefits]);

  /**
   * Upload the selected Membership List CSV file.
   */
  const handleUploadMembershipList = async () => {
    if (!mlFile) return;
    setStage(Stages.UPLOADING_MEMBERSHIP_LIST);

    try {
      const data = new FormData();
      data.append("file", mlFile);

      const response = await authenticatedAxios.post(
        `${API_URL}/api/audit/${auditId}/upload-membership-list`,
        data,
      );

      setMembershipList(response.data);
      setStage(Stages.MEMBERSHIP_LIST_UPLOADED);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setUploadMLError(error.response.data.message);
      } else {
        setUploadMLError("An unexpected error occurred.");
      }

      setStage(Stages.MEMBERSHIP_LIST_FILE_SELECTED);
    }
  };

  /**
   * Clear the upload error when a new file is selected.
   */
  useEffect(() => {
    mlFile && setUploadMLError(null);
  }, [mlFile]);

  /**
   * Start configuration of the audit settings.
   */
  const handleConfigure = async () => {
    setStage(Stages.LOADING_AUDIT_SETTINGS);

    try {
      const response = await authenticatedAxios.post(
        `${API_URL}/api/audit/${auditId}/configure`,
        {},
      );

      setConfig(response.data);
      setStage(Stages.CONFIGURE_AUDIT_SETTINGS);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setConfigError(error.response.data.message);
      } else {
        setConfigError("An unexpected error occurred.");
      }

      setStage(Stages.MEMBERSHIP_LIST_UPLOADED);
    }
  };

  /**
   * Clear the config setup error when any changes are made to the config.
   */
  useEffect(() => {
    config && setConfigError(null);
  }, [config]);

  /**
   * Download the full audit report as an XSLX file.
   */
  const handleGenerateXLSX = async () => {
    try {
      setIsGeneratingXLSX(true);
      const workbook = new XLSXWorkbook();

      results.forEach((result) => {
        workbook.addSheet(result.title, result.data);
      });

      await workbook.save(`audit-${auditId}.xlsx`);
    } catch (error) {
      console.error(error);
      setXLSXError("An unexpected error occurred.");
    } finally {
      setIsGeneratingXLSX(false);
    }
  };

  /**
   * Clear the download report error when a new report is generated.
   */
  useEffect(() => {
    results && setXLSXError(null);
  }, [results]);

  /**
   * Fetch the list of employers & benefits.
   * This populates the employer & benefit selection dropdown inputs.
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [employerResponse, benefitResponse] = await Promise.all([
          authenticatedAxios.get(`${API_URL}/api/employers`),
          authenticatedAxios.get(`${API_URL}/api/benefits`),
        ]);

        // noinspection JSUnresolvedReference
        const employers = employerResponse.data.rows;

        // noinspection JSUnresolvedReference
        const benefits = benefitResponse.data.rows;

        setEmployers(employers);
        setBenefits(benefits);

        setEmployerSelectionOptions(
          employers.map((e) => ({
            value: getEmployerValue(e, "id"),
            label: `(${getEmployerValue(e, "id")}) ${getEmployerValue(e, "name")}`,
          })),
        );
        setBenefitSelectionOptions(
          benefits.map((b) => ({
            value: getBenefitValue(b, "id"),
            label: `(${getBenefitValue(b, "id")}) ${getBenefitValue(b, "name")}`,
          })),
        );

        setStage(Stages.SELECT_BENEFITS);
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setInitError(error.response.data.message);
        } else {
          setInitError("An unexpected error occurred.");
        }

        setStage(Stages.LOADING_EMPLOYERS_AND_BENEFITS);
      }
    };

    // noinspection JSIgnoredPromiseFromCall
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Keep scroll at the bottom of the page.
   */
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [scrollRef, stage]);

  /**
   * Reset the application state when the stage changes.
   */
  useEffect(() => {
    if (stage < Stages.SELECT_BENEFITS) {
      setSelectedEmployers([]);
      setSelectedBenefits([]);
      setBenefitSelectionOptions([]);
    }

    if (stage < Stages.SELECT_MEMBERSHIP_LIST_FILE) {
      setMLFile(null);
    }

    if (stage < Stages.MEMBERSHIP_LIST_UPLOADED) {
      setMembershipList(null);
      setConfig(null);
    }

    if (stage < Stages.AUDIT_COMPLETED) {
      setResults(null);
    }
  }, [stage]);

  // noinspection JSCheckFunctionSignatures
  return (
    <div className="flex flex-col gap-y-4">
      <h1 className="text-xl font-medium text-gray-800">
        Audit: Membership Lists vs. Ben Enrollments
      </h1>

      {stage >= Stages.LOADING_EMPLOYERS_AND_BENEFITS && (
        <div className="flex w-full flex-col gap-y-4 rounded border border-gray-300 bg-white p-8">
          <div className="flex flex-col gap-y-1">
            <h1 className="text-lg font-semibold">Configure Ben Side</h1>
            <p className="text-sm text-gray-500">
              These benefits could contain the exact enrollments that need to be
              audited against the uploaded membership list.
            </p>
          </div>

          {/* Benefit Selection Setup Error */}
          {initError && (
            <div className="rounded border border-red-300 bg-red-100 p-4 text-sm text-red-500">
              {initError}
            </div>
          )}

          {/* Select Employers */}
          <div className="flex flex-col gap-y-2">
            <div className="flex flex-col gap-y-1">
              <div className="flex items-center gap-x-1">
                <label className="text-sm font-medium text-gray-800">
                  Select Employers
                </label>
                <p className="text-sm text-gray-500">(to filter benefits)</p>
              </div>

              <Select
                className="text-sm text-gray-800"
                placeholder={
                  stage === Stages.LOADING_EMPLOYERS_AND_BENEFITS
                    ? "Loading employers..."
                    : "Select employers..."
                }
                isLoading={stage === Stages.LOADING_EMPLOYERS_AND_BENEFITS}
                isMulti
                value={selectedEmployers}
                onChange={(selected) => {
                  setSelectedEmployers(selected);

                  // noinspection JSUnresolvedReference
                  const employerIds = new Set(selected.map((e) => e.value));

                  // noinspection JSUnresolvedReference
                  setBenefitSelectionOptions(
                    benefits
                      .filter(
                        (b) =>
                          employerIds.size === 0 ||
                          employerIds.has(getBenefitValue(b, "employer_id")),
                      )
                      .map((b) => ({
                        value: getBenefitValue(b, "id"),
                        label: `(${getBenefitValue(b, "id")}) ${getBenefitValue(b, "name")}`,
                      })),
                  );
                }}
                options={employerSelectionOptions}
              />
            </div>

            {selectedEmployers.length > 0 && (
              <Table
                headers={["Employer ID", "Employer Name"]}
                rows={employers.filter((e) =>
                  selectedEmployers.some(
                    (selection) =>
                      getEmployerValue(e, "id") === selection.value,
                  ),
                )}
              />
            )}
          </div>

          {/* Select Benefits */}
          <div className="flex flex-col gap-y-2">
            <div className="flex flex-col gap-y-1">
              <label className="text-sm font-medium text-gray-800">
                Select Benefits
              </label>
              <Select
                className="text-sm text-gray-800"
                placeholder={
                  stage === Stages.LOADING_EMPLOYERS_AND_BENEFITS
                    ? "Loading benefits..."
                    : "Select benefits..."
                }
                isLoading={stage === Stages.LOADING_EMPLOYERS_AND_BENEFITS}
                isMulti
                value={selectedBenefits}
                onChange={(selected) => {
                  setSelectedBenefits(selected);
                  setStage(
                    selected.length > 0
                      ? Stages.BENEFITS_SELECTED
                      : Stages.SELECT_BENEFITS,
                  );
                }}
                options={benefitSelectionOptions}
              />
            </div>

            {selectedBenefits.length > 0 && (
              <Table
                headers={[
                  "Benefit ID",
                  "Benefit Name",
                  "Employer ID",
                  "Employer Name",
                  "No. of Active Enrollments",
                ]}
                rows={benefits.filter((b) =>
                  selectedBenefits.some(
                    (selection) => getBenefitValue(b, "id") === selection.value,
                  ),
                )}
              />
            )}
          </div>

          {/* Preview Button */}
          {stage >= Stages.BENEFITS_SELECTED && (
            <button
              className="flex items-center justify-center gap-x-2 rounded border border-transparent bg-teal-600 px-4 py-2 text-center text-sm font-medium text-white transition-colors hover:bg-teal-700 focus:outline-none active:bg-teal-800"
              onClick={handleFetchBenEnrollments}
            >
              <p>
                {stage === Stages.ACQUIRING_BENEFIT_ENROLLMENTS
                  ? "Loading..."
                  : "Preview"}
              </p>
              <Icon
                icon={
                  stage === Stages.ACQUIRING_BENEFIT_ENROLLMENTS
                    ? "clock"
                    : "next"
                }
              />
            </button>
          )}

          {/* ML Upload Error */}
          {benefitSelectionError && (
            <div className="rounded border border-red-300 bg-red-100 p-4 text-sm text-red-500">
              {benefitSelectionError}
            </div>
          )}

          {/* Benefit Enrollments Preview */}
          {stage >= Stages.BENEFIT_ENROLLMENTS_ACQUIRED && (
            <PaginatedTable
              headers={enrollments.headers}
              rows={enrollments.rows}
              rowsPerPage={5}
              extraInfo="Previewing first 200 rows..."
            />
          )}

          {/* Next */}
          {stage >= Stages.BENEFIT_ENROLLMENTS_ACQUIRED && (
            <button
              className="flex items-center justify-center gap-x-2 rounded border border-transparent bg-teal-600 px-4 py-2 text-center text-sm font-medium text-white transition-colors hover:bg-teal-700 focus:outline-none active:bg-teal-800"
              onClick={() => setStage(Stages.SELECT_MEMBERSHIP_LIST_FILE)}
            >
              <p>Next</p>
              <Icon icon="next" />
            </button>
          )}
        </div>
      )}

      {/* Configure Provider Side */}
      {stage >= Stages.SELECT_MEMBERSHIP_LIST_FILE && (
        <div className="flex w-full flex-col gap-y-4 rounded border border-gray-300 bg-white p-8">
          <div className="flex flex-col gap-y-1">
            <h1 className="text-lg font-semibold">Configure Provider Side</h1>
            <p className="text-sm text-gray-500">
              Upload a valid Membership List CSV (Comma Separated Values) file.
            </p>
          </div>

          <div className="flex flex-col gap-y-1 rounded bg-gray-100 p-4 text-sm">
            <p className="text-gray-500">
              Please refer to the documentation at the following link for
              information on how a CSV file should be structured:
            </p>
            <a
              href="https://www.notion.so/thanksben/Auditing-Audit-Tool-101-15bc5fff8f8d805f8d7ffa24caa21431?pvs=4#15bc5fff8f8d800f8308d29d1e3ee6dd"
              target="_blank"
              rel="noreferrer"
              className="text-purple-600 hover:underline font-medium transition-colors hover:text-purple-700 active:text-purple-800"
            >
              Auditing & Audit Tool 101 - CSV Structure
            </a>
          </div>

          {/* Upload file */}
          <FileInput
            file={mlFile}
            accept=".csv"
            onDrop={(e) => {
              e.preventDefault();
              if (e.dataTransfer.files.length > 0)
                setMLFile(e.dataTransfer.files[0]);
              setStage(Stages.MEMBERSHIP_LIST_FILE_SELECTED);
            }}
            onSelect={(e) => {
              if (e.target.files.length > 0) setMLFile(e.target.files[0]);
              setStage(Stages.MEMBERSHIP_LIST_FILE_SELECTED);
            }}
          />

          {/* Upload button */}
          {stage >= Stages.MEMBERSHIP_LIST_FILE_SELECTED && (
            <button
              className="flex items-center justify-center gap-x-2 rounded border border-transparent bg-teal-600 px-4 py-2 text-center text-sm font-medium text-white transition-colors hover:bg-teal-700 focus:outline-none active:bg-teal-800"
              onClick={handleUploadMembershipList}
              disabled={stage === Stages.UPLOADING_MEMBERSHIP_LIST}
            >
              <p>
                {stage === Stages.UPLOADING_MEMBERSHIP_LIST
                  ? "Uploading..."
                  : "Upload"}
              </p>
              <Icon icon="upload" size="sm" />
            </button>
          )}

          {/* ML Upload Error */}
          {uploadMLError && (
            <div className="rounded border border-red-300 bg-red-100 p-4 text-sm text-red-500">
              {uploadMLError}
            </div>
          )}

          {/* Uploaded File Preview */}
          {stage >= Stages.MEMBERSHIP_LIST_UPLOADED && (
            <PaginatedTable
              headers={membershipList.headers}
              rows={membershipList.rows}
              rowsPerPage={5}
              extraInfo="Previewing first 200 rows..."
            />
          )}

          {/* Next */}
          {stage >= Stages.MEMBERSHIP_LIST_UPLOADED && (
            <button
              className="flex items-center justify-center gap-x-2 rounded border border-transparent bg-teal-600 px-4 py-2 text-center text-sm font-medium text-white transition-colors hover:bg-teal-700 focus:outline-none active:bg-teal-800"
              onClick={handleConfigure}
              disabled={stage === Stages.LOADING_AUDIT_SETTINGS}
            >
              <p>
                {stage === Stages.LOADING_AUDIT_SETTINGS
                  ? "Loading..."
                  : "Next"}
              </p>
              <Icon
                icon={
                  stage === Stages.LOADING_AUDIT_SETTINGS ? "clock" : "next"
                }
              />
            </button>
          )}

          {/* Config Setup Error */}
          {configError && (
            <div className="rounded border border-red-300 bg-red-100 p-4 text-sm text-red-500">
              {configError}
            </div>
          )}
        </div>
      )}

      {/* Configuration */}
      {stage >= Stages.CONFIGURE_AUDIT_SETTINGS && (
        <div className="flex w-full flex-col gap-y-4 rounded border border-gray-300 bg-white p-8">
          <div className="flex flex-col gap-y-1">
            <h1 className="text-lg font-semibold">Audit Settings</h1>
            <p className="text-sm text-gray-500">
              Provide any information that the membership list may contain below
              & map columns and values accordingly.
            </p>
            <p className="text-sm text-gray-500">
              The more information the tool receives, the better the audit
              results will be.
            </p>
          </div>

          {/* Config List */}
          <AuditConfig
            data={config}
            onChange={(path, value) =>
              setConfig(_.set(_.cloneDeep(config), path, value))
            }
          />

          {/* Audit button */}
          <button
            className="flex items-center justify-center gap-x-2 rounded border border-transparent bg-teal-600 px-4 py-2 text-center text-sm font-medium text-white transition-colors hover:bg-teal-700 focus:outline-none active:bg-teal-800"
            onClick={handleAudit}
            disabled={stage === Stages.AUDITING}
          >
            <p>{stage === Stages.AUDITING ? "Auditing..." : "Start Audit"}</p>
            <Icon icon="audit" size="sm" />
          </button>

          {/* Audit Error */}
          {auditError && (
            <div className="rounded border border-red-300 bg-red-100 p-4 text-sm text-red-500">
              {auditError}
            </div>
          )}
        </div>
      )}

      {/* Audit Results */}
      {stage >= Stages.AUDIT_COMPLETED && (
        <div className="flex flex-col gap-y-4">
          <div className="mb-2 mt-4 w-full rounded border-b-2 border-gray-300 bg-white" />

          <div className="flex items-center justify-between gap-x-2">
            <h1 className="text-lg font-semibold">Audit Results</h1>

            {/* Generate .XLSX */}
            <button
              className="flex items-center justify-center gap-x-2 rounded border border-transparent bg-teal-600 px-4 py-2 text-center text-sm font-medium text-white transition-colors hover:bg-teal-700 focus:outline-none active:bg-teal-800"
              disabled={isGeneratingXLSX}
              onClick={handleGenerateXLSX}
            >
              <Icon icon={isGeneratingXLSX ? "clock" : "download"} size="sm" />
              <p>{isGeneratingXLSX ? "Generating..." : "Generate .XLSX"}</p>
            </button>
          </div>

          {/* XLSX Generation Error */}
          {xlsxError && (
            <div className="rounded border border-red-300 bg-red-100 p-4 text-sm text-red-500">
              {xlsxError}
            </div>
          )}

          {/* Results */}
          <div className="flex flex-col gap-y-2">
            {results
              .filter((result) => result.type === "TABLE")
              .map((result, index) => (
                <Result
                  key={index}
                  title={result.title}
                  icon={result.icon}
                  count={result.count}
                  data={result.data}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
