export const isObject = (obj) => (obj ?? false)?.constructor?.name === "Object";
export const getValue = (obj) => (isObject(obj) ? obj.value : obj);

const employersColumnMap = {
  id: 0,
  name: 1,
};

export const getEmployerValue = (obj, key) =>
  getValue(obj[employersColumnMap[key]]);

const benefitsColumnMap = {
  id: 0,
  name: 1,
  employer_id: 2,
  employer_name: 3,
  no_of_active_enrollments: 4,
};

export const getBenefitValue = (obj, key) =>
  getValue(obj[benefitsColumnMap[key]]);
