import Icon from "./Icon";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";

const BaseValue = ({ value }) => {
  return <span className="text-gray-700">{value}</span>;
};

const TitleValue = ({ value }) => {
  return <span className="font-semibold text-gray-800">{value}</span>;
};

const BooleanValue = ({ value }) => {
  return value ? <Icon icon="check" size="sm" /> : <Icon icon="x" size="xs" />;
};

const LinkValue = ({ value, link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="font-medium text-purple-600 transition-colors hover:text-purple-700 hover:underline"
    >
      {value}
    </a>
  );
};

const EmptyValue = () => {
  return (
    <span className="rounded bg-gray-200 px-2 py-1 font-semibold leading-tight text-gray-700">
      Empty
    </span>
  );
};

const Value = ({ data }) => {
  // Non-cell data
  if (data === null || data === "") {
    return <EmptyValue />;
  }
  if (typeof data !== "object") {
    return <BaseValue value={data} />;
  }

  // Cell data
  if (data.value === null || data.value === "") {
    return <EmptyValue />;
  }
  if (data.type === "BASE") {
    return <BaseValue value={data.value} />;
  }
  if (data.type === "TITLE") {
    return <TitleValue value={data.value} />;
  }
  if (data.type === "BOOLEAN") {
    return <BooleanValue value={data.value} />;
  }
  if (data.type === "LINK") {
    return <LinkValue value={data.value} link={data.link} />;
  }

  return <EmptyValue />;
};

/**
 * Renders the base structure of a table, displaying rows of data with configurable headers.
 * (To use in both regular and paginated table components)
 *
 * Displays data in a tabular format where each row is mapped from the provided `rows`
 * and each header corresponds to a key in the row objects.
 * - If a cell value is `null` or an empty string, it displays an "Empty" placeholder.
 * - If a cell value is `true`, it displays a checkmark icon.
 * - If a cell value is `false`, it displays a cross icon.
 *
 * @param {Array<Array>} rows - Array of data objects representing table rows. Each object should have keys matching the `headers`.
 * @param {Array<String>} headers - Array of strings representing the table headers, which correspond to the keys in each row object.
 * @returns {JSX.Element} A table element with rows and headers.
 */
function TableBase({ headers, rows }) {
  return (
    <table className="text-overflow-ellipsis w-full overflow-hidden whitespace-nowrap">
      <thead>
        <tr className="border-b border-gray-300 bg-gray-50 text-left text-xs font-semibold tracking-wide text-gray-500">
          {headers.map((header) => (
            <th className="p-3">{String(header)}</th>
          ))}
        </tr>
      </thead>
      <tbody className="divide-y bg-white">
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex} className="text-gray-700">
            {row.map((value, columnIndex) => (
              <td key={columnIndex} className="p-3 text-xs">
                <Value data={value} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

TableBase.propTypes = {
  rows: PropTypes.array,
  headers: PropTypes.array.isRequired,
};

TableBase.defaultProps = {
  rows: [],
};

/**
 * Renders a table with the provided rows and headers.
 *
 * @param {Array<Object>} rows - Array of data objects representing table rows. Each object should have keys matching the `headers`.
 * @param {Array<String>} headers - Array of strings representing the table headers, which correspond to the keys in each row object.
 * @returns {JSX.Element} A table element with rows and headers.
 */
export function Table({ rows, headers }) {
  return (
    <div className="w-full overflow-hidden overflow-x-auto rounded border border-gray-300">
      <TableBase rows={rows} headers={headers} />
    </div>
  );
}

Table.propTypes = {
  rows: PropTypes.array,
  headers: PropTypes.array.isRequired,
};

Table.defaultProps = {
  rows: [],
};

/**
 * Renders a paginated table with the provided rows and headers.
 * Result will be displayed in a paginated table format with configurable rows per page.
 * Buttons are provided to navigate between pages.
 *
 * @param {Array<Object>} rows - Array of data objects representing table rows. Each object should have keys matching the `headers`.
 * @param {Array<String>} headers - Array of strings representing the table headers, which correspond to the keys in each row object.
 * @param {Number} rowsPerPage - Number of rows to display per page.
 * @param {String} extraInfo - Extra information to display in the pagination footer.
 * @returns {JSX.Element} A table element with rows and headers.
 */
export function PaginatedTable({ rows, headers, rowsPerPage, extraInfo }) {
  const [currentPage, setCurrentPage] = useState(1);

  // Total number of pages
  const totalPages = useMemo(() => {
    return Math.ceil(rows.length / rowsPerPage);
  }, [rows.length, rowsPerPage]);

  // Sliced data based on current page
  const currentData = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    return rows.slice(startIndex, startIndex + rowsPerPage);
  }, [currentPage, rows, rowsPerPage]);

  // Pagination text, e.g. "Showing 1-10 of 100"
  const paginationText = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, rows.length);
    return `Showing ${startIndex + 1}-${endIndex} of ${rows.length}`;
  }, [currentPage, rows.length, rowsPerPage]);

  return (
    <div>
      <div className="w-full overflow-hidden overflow-x-auto rounded-t border border-gray-300">
        <TableBase rows={currentData} headers={headers} />
      </div>
      <div className="grid grid-cols-3 w-full items-center gap-4 rounded-b border-x border-b border-gray-300 bg-gray-50 px-4 py-3 text-xs font-semibold text-gray-500">
        <div className="flex justify-start items-center gap-x-1">
          {paginationText}
        </div>
        <div className="flex justify-center items-center gap-x-1">
          {extraInfo}
        </div>

        <div className="flex justify-end items-center gap-x-1">
          <button
            className="focus:shadow-outline-purple p-1 focus:outline-none"
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
          >
            <Icon icon="double-caret-left" />
          </button>
          <button
            className="focus:shadow-outline-purple p-1 focus:outline-none"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <Icon icon="caret-left" size="sm" />
          </button>

          <div className="w-24 text-center">{`Page ${currentPage} of ${totalPages}`}</div>

          <button
            className="focus:shadow-outline-purple p-1 focus:outline-none"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <Icon icon="caret-right" size="sm" />
          </button>
          <button
            className="focus:shadow-outline-purple p-1 focus:outline-none"
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
          >
            <Icon icon="double-caret-right" />
          </button>
        </div>
      </div>
    </div>
  );
}

PaginatedTable.propTypes = {
  rows: PropTypes.array,
  headers: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number,
  extraInfo: PropTypes.string,
};

PaginatedTable.defaultProps = {
  rows: [],
  rowsPerPage: 10,
};
